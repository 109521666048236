<template>
  <v-app> </v-app>
</template>

<script>
import * as firebase from "firebase";
import Vue from "vue";
export default {
  mounted() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.$store.commit("SET_USER", null);
        this.$router.push({ name: "login" });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
};
</script>
